
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AddButton extends Vue {
  @Prop({ required: false, default: false })
  public loading!: boolean;
}
