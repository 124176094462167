var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.label')),"type":_vm.validationErrors && _vm.validationErrors.label ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.label ? _vm.validationErrors.label : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.label')),"required":""},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})],1),_c('hr'),(!_vm.disabledFields.includes('given_name'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.given_name')),"type":_vm.validationErrors && _vm.validationErrors.given_name ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.given_name
        ? _vm.validationErrors.given_name
        : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.given_name'))},model:{value:(_vm.givenName),callback:function ($$v) {_vm.givenName=$$v},expression:"givenName"}})],1):_vm._e(),(!_vm.disabledFields.includes('family_name'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.family_name')),"type":_vm.validationErrors && _vm.validationErrors.family_name ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.family_name
        ? _vm.validationErrors.family_name
        : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.family_name'))},model:{value:(_vm.familyName),callback:function ($$v) {_vm.familyName=$$v},expression:"familyName"}})],1):_vm._e(),(!_vm.disabledFields.includes('organization'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.organization')),"type":_vm.validationErrors && _vm.validationErrors.organization ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.organization
        ? _vm.validationErrors.organization
        : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.organization'))},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1):_vm._e(),(!_vm.disabledFields.includes('street'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.street')),"type":_vm.validationErrors && _vm.validationErrors.street ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.street
        ? _vm.validationErrors.street
        : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.street'))},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1):_vm._e(),(!_vm.disabledFields.includes('postal_code'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.postal_code')),"type":_vm.validationErrors && _vm.validationErrors.postal_code ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.postal_code
        ? _vm.validationErrors.postal_code
        : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.postal_code'))},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1):_vm._e(),(!_vm.disabledFields.includes('city'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.city')),"type":_vm.validationErrors && _vm.validationErrors.city ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.city ? _vm.validationErrors.city : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.city'))},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1):_vm._e(),(!_vm.disabledFields.includes('country_code'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.country')),"type":_vm.validationErrors && _vm.validationErrors.country ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.country
        ? _vm.validationErrors.country
        : ''}},[_c('b-autocomplete',{attrs:{"icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.country')),"open-on-focus":true,"data":_vm.filteredCountries,"group-field":"type","group-options":"countries","field":"name"},on:{"select":_vm.onCountrySelect},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1):_vm._e(),(!_vm.disabledFields.includes('state'))?_c('b-field',{attrs:{"horizontal":_vm.formSettings.horizontal,"label":String(_vm.$t('modules.address.labels.state')),"type":_vm.validationErrors && _vm.validationErrors.state ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.state ? _vm.validationErrors.state : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.address.placeholder.state'))},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }