
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ICountry } from "@/services/CountryService";
import Str from "@/helper/Str";
import IAddress from "@/interfaces/IAddress";
import Json from "@/helper/Json";

@Component({
  components: {},
})
export default class Address extends Vue {
  @Prop({ required: true })
  protected value!: IAddress;

  @Prop({ required: true })
  protected formSettings!: any;

  @Prop({
    required: false,
    default: () => {
      return {};
    },
  })
  protected validationErrors!: any;

  @Prop({
    required: true,
  })
  protected disabledFields!: Array<
    | "family_name"
    | "given_name"
    | "organization"
    | "country_code"
    | "street"
    | "state"
    | "city"
    | "postal_code"
    | "longitude"
    | "latitude"
    | "is_primary"
  >;

  @Watch("value", { immediate: true, deep: true })
  protected onValueChanged(value: IAddress, oldValue: any) {
    if (value) {
      this.setFormValues(value);
    }
  }

  @Watch("formValues", { immediate: false, deep: true })
  protected onFormValuesChanged(formValues: any, oldFormValues: any) {
    this.$emit("input", formValues);
  }

  protected id?: number;
  protected label = "";
  protected givenName = "";
  protected familyName: string | null = null;
  protected organization: string | null = null;
  protected country: any = null;
  protected selectedCountry: ICountry | null = null;
  protected countryCode: string | null = null;
  protected street: string | null = null;
  protected state: string | null = null;
  protected city: string | null = null;
  protected postalCode: string | null = null;
  protected latitude: number | null = null;
  protected longitude: number | null = null;
  protected isPrimary: boolean | null = null;

  protected setFormValues(address: IAddress): void {
    this.id = address.id;
    this.label = address.label;
    this.givenName = address.given_name;
    this.familyName = address.family_name ?? null;
    this.organization = address.organization ?? null;
    this.selectedCountry = address.country_code
      ? this.$countryService.getCountryByCode(address.country_code)
      : null;
    this.country = address.country_code
      ? this.$countryService.getCountryByCode(address.country_code)?.name ??
        null
      : null;
    this.countryCode = address.country_code ?? null;
    this.street = address.street ?? null;
    this.state = address.state ?? null;
    this.city = address.city ?? null;
    this.postalCode = address.postal_code ?? null;
    this.latitude = address.latitude ?? null;
    this.longitude = address.longitude ?? null;
    this.isPrimary = address.is_primary ?? null;
  }

  protected get formValues(): IAddress {
    let formValues: IAddress = {
      label: this.label,
      given_name: this.givenName,
      family_name: this.familyName,
      organization: this.organization,
      country_code: this.countryCode,
      street: this.street,
      state: this.state,
      city: this.city,
      postal_code: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      is_primary: this.isPrimary,
    };

    if (this.id) {
      formValues["id"] = this.id;
    }
    return Json.removeNulls(formValues);
  }

  protected get filteredCountries(): any {
    const filteredCountries: Array<ICountry> = this.$countryService
      .getCountries()
      .filter((country: any) => {
        if (this.country && this.country.length > 0) {
          return country.name.indexOf(this.country) >= 0;
        }
        return true;
      });

    const filteredFeaturedCountries: Array<ICountry> = this.$countryService
      .getFeaturedCountries()
      .filter((country: any) => {
        if (this.country && this.country.length > 0) {
          return (
            country.name.toLowerCase().indexOf(this.country.toLowerCase()) >= 0
          );
        }
        return true;
      });

    return [
      {
        type: Str.capitalize(
          String(this.$t("modules.address.frequently_chosen"))
        ),
        countries: filteredFeaturedCountries,
      },
      {
        type: Str.capitalize(String(this.$t("modules.address.countries"))),
        countries: filteredCountries,
      },
    ];
  }

  protected onCountrySelect(option: ICountry) {
    if (option?.name) {
      this.selectedCountry = { ...option };
      this.country = option.name;
    }
  }
}
