
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import IAddress from "@/interfaces/IAddress";
import Address from "@/components/forms/common/Address.vue";
import AddButton from "@/components/buttons/AddButton.vue";
import { Address as AddressConfig } from "@/config/Modules";

@Component({
  components: { AddButton, Address },
})
export default class AddressesForm extends Vue {
  @Prop({ required: false, default: null })
  protected value!: Array<IAddress> | null;

  @Prop({
    required: false,
    default: () => {
      return {
        horizontal: false,
      };
    },
  })
  protected formSettings!: any;

  @Prop({
    required: false,
    default: () => {
      return ["longitude, latitude"];
    },
  })
  protected disabledFields!: Array<
    | "family_name"
    | "given_name"
    | "organization"
    | "country_code"
    | "street"
    | "state"
    | "city"
    | "postal_code"
    | "longitude"
    | "latitude"
    | "is_primary"
  >;

  @Prop({ required: false, default: null })
  protected validationErrors!: any;

  @Watch("value", { immediate: true, deep: true })
  protected onValueChanged(addresses: Array<IAddress>): void {
    if (addresses) {
      this.addressesList = addresses;
    }
  }

  @Watch("addressesList", { immediate: false, deep: true })
  onAddressesListChanged(addresses: Array<IAddress>): void {
    if (addresses) {
      this.$emit("input", addresses);
    }
  }

  protected addressesList: Array<IAddress> = [];
  protected openedAddressIdx = 0;

  protected emptyAddress: IAddress = {
    given_name: "---",
    label: "",
  };

  protected onAddAddressClicked(): void {
    this.addressesList.push({
      ...this.emptyAddress,
      label:
        String(this.$t("general.address")) +
        " " +
        String(this.addressesList.length + 1),
      is_primary: !this.hasCustomerPrimaryAddress(),
    });
  }

  protected onRemoveAddress(idx: number, force = false): void {
    if (force) {
      if (this.addressesList[idx]?.id) {
        this.$apiService.delete(
          [AddressConfig.resource, this.addressesList[idx].id].join("/")
        );
      }
      this.addressesList.splice(idx, 1);
      return;
    }

    this.$dialogsService
      .deleteConfirmDialog(String(this.$t("general.address")))
      .then((resource: any) => {
        this.onRemoveAddress(idx, true);
      });
  }

  protected onPrimaryChecked(isPrimary: boolean, addressIdx: number): void {
    console.debug("on prim checked", isPrimary, addressIdx);
    if (isPrimary) {
      this.addressesList.forEach((address: IAddress, addressIndex) => {
        if (addressIndex !== addressIdx) {
          this.addressesList[addressIndex].is_primary = false;
        }
      });
    } else {
      this.$buefy.dialog.alert(
        String(this.$t("modules.address.please_chose_other_primary_address"))
      );
      this.$nextTick(() => {
        this.addressesList[addressIdx].is_primary = true;
      });
    }
  }

  protected hasCustomerPrimaryAddress(): boolean {
    return (
      this.addressesList.filter((address: IAddress) => {
        return address.is_primary;
      }).length > 0
    );
  }
}
